<template>
  <div class="cards-filters-beeline">


    <div class="cards-filters-beeline-side">
      <search-input
        v-if="isFilterVisible('search')"
        style="width: 300px"
        v-model="filters.search"
        @change="filtersChanged"
      >
      </search-input>

      <cards-year-select
        v-if="isFilterVisible('years')"
        style="width:100px"
        v-model="filters.years"
        @change="filtersChanged"
      ></cards-year-select>

      <cards-period-select
        v-if="isFilterVisible('periods')"
        style="width:100px"
        v-model="filters.periods"
        @change="filtersChanged"
      ></cards-period-select>

      <cards-stage-select
        v-if="isFilterVisible('stages')"
        multiple
        v-model="filters.stages"
        @change="filtersChanged"
      ></cards-stage-select>

      <cards-stage-negotiation-select
        v-if="isFilterVisible('negotiations')"
        v-model="filters.negotiations"
        multiple
        @change="filtersChanged"
      ></cards-stage-negotiation-select>

      <!--      <search-input-->
      <!--        placeholder="Ответственный"-->
      <!--        style="width: 200px"-->
      <!--        v-model="filters.negotiation_user_fio"-->
      <!--        @change="filtersChanged"-->
      <!--      >-->
      <!--      </search-input>-->
    </div>


    <div class="cards-filters-beeline-side">
      <el-checkbox
        v-if="isFilterVisible('my_turn')"
        style="margin-right: 0"
        v-model="filters.my_turn"
        border
        :true-label="1"
        :false-label="null"
        @change="filtersChanged"
      >
        Карты на мне
      </el-checkbox>
      <el-checkbox
        v-if="isFilterVisible('my')"
        style="margin-right: 0"
        v-model="filters.my"
        border
        :true-label="1"
        :false-label="null"
        @change="preCheckChange('my', $event)"
      >
        Мои карты
      </el-checkbox>
      <el-checkbox
        v-if="isFilterVisible('subordinates')"
        style="margin-left: 0"
        v-model="subordinates"
        border
        @change="preCheckChange('subordinates', $event)"
      >
        Карты моих подчиненных
      </el-checkbox>
      <el-switch
        v-if="subordinates"
        style="margin-top: 10px"
        v-model="allSubordinates"
        inactive-text="Непосредственные"
        active-text="Все"
        @change="filtersChanged"
      >
      </el-switch>
    </div>

  </div>
</template>

<script>

import SearchInput from "@/components/filters/SearchInput";
import CardsYearSelect from "@/components/filters/cards/CardsYearSelect";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";
import CardsPeriodSelect from "@/components/filters/cards/CardsPeriodSelect.vue";

export default {
  name: "cards-page-filters",
  components: {CardsPeriodSelect, CardsStageNegotiationSelect, CardsStageSelect, CardsYearSelect, SearchInput},

  props: {
    value: {},
    hidden: {type: Array},
  },

  computed: {},
  watch: {},
  data() {
    return {
      subordinates: false,
      allSubordinates: false,

      filters: {
        search: null,
        years: [],
        periods: [],
        stages: [],
        negotiations: [],
        negotiation_user_fio: null,
        my: null,
        my_turn: null,
        subordinates: null,
      },
    }
  },
  beforeMount() {
    this.filters.my_turn = this.$route.query.my_turn ? 1 : null;
    if( this.filters.my_turn ){
      this.subordinates = false;
    }
  },
  mounted() {
    this.filters = {...this.filters, ...this.value};
    if( this.filters.my_turn ){
      this.filters.my = null;
      this.filters.allSubordinates = 1;
    }

    const date = new Date();
    let defaultYear = date.getFullYear();
    if( (date.getMonth()+1) <= 2 ){ // До конца февраля оставляем прошлый год по умолчанию
      defaultYear--;
    }
    this.filters.years.push(defaultYear);

    this.filtersChanged()
  },
  methods: {
    filtersChanged() {

      if( this.subordinates && this.allSubordinates ){
        this.filters.subordinates = this.$constants.user.subordinates.SUBORDINATES_ALL;
      } else if( this.subordinates && !this.allSubordinates ){
        this.filters.subordinates = this.$constants.user.subordinates.SUBORDINATES_DIRECT;
      } else {
        this.filters.subordinates = this.$constants.user.subordinates.SUBORDINATES_NONE;
      }

      this.$emit('input', this.filters);
      this.$emit('change', this.filters);
    },

    preCheckChange(filter, value) {
      if (filter === 'my' && value) {
        this.subordinates = false;
        this.allSubordinates = false;
      }
      if (filter === 'subordinates' && value) {
        this.filters.my = null;
        this.filters.allSubordinates = null;
      }
      if (filter === 'subordinates' && !value) {
        this.filters.allSubordinates = null;
      }
      this.filtersChanged();
    },

    isFilterVisible(filterName){
      if( this.hidden && this.hidden.includes(filterName) ){
        return false;
      }
      return true;
    }
  }
}
</script>


<style lang="scss">

.cards-filters-beeline {
  display: flex;
  justify-content: space-between;

  &-side {
    display: flex;
    gap: 1.5rem;
  }
}
</style>